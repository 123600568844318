import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_region,
});
var s3 = new AWS.S3();

//Configure Storage with S3 bucket information
export const SetS3Config = (id, file) => {
  var params = {
    Body: file,
    Bucket: "vod-dev-source71e471f1-1b2uzhslymmqx",
    Key: id,
  };
  return s3.upload(params);
};
