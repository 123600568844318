import "./App.css";
import { S3MediaUpload } from "./coponent/s3MediaUpload";
import { ToastProvider } from "./context/Toast";
import { Login } from "./coponent/login";
import { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";

function App() {
  const token = localStorage.getItem("token");
  const [hasToken, setHasToken] = useState(false);
  
  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    if (token) {
      localStorage.removeItem("token")   
    }
  });
  return (
    <ToastProvider>
      <ThemeProvider>
        {!token ? (
          <Login setHasToken={() => setHasToken(true)} />
        ) : (
          <S3MediaUpload setHasToken={() => setHasToken(false)}/>
        )}
      </ThemeProvider>
    </ToastProvider>
  );
}

export default App;
