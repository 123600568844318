import { createRef, useContext, useState } from "react";
import { SetS3Config } from "../util/awsService";
import DeleteIcon from "@material-ui/icons/Delete";
import cloud from "../assets/cload.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContext, ToastTypes } from "../context/Toast";
import { Button } from "@material-ui/core";

export const S3MediaUpload = ({ setHasToken }) => {
  let upload = createRef();

  const [state, setState] = useState({
    imageFile: "",
    imageName: "",
    response: "",
    percentUpload: null,
  });

  const toastContext = useContext(ToastContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setHasToken();
  };

  const uploadImage = async () => {
    const id = `assets01/${upload.files[0].name}`;
    const file = upload.files[0];
    const result = SetS3Config(id, file);
    result.on("httpUploadProgress", (Progress) => {
      const { loaded, total } = Progress;
      const progress = (loaded / total) * 100;
      setState({
        percentUpload: progress,
      });
    });
    result.send(async (err, data) => {
      if (!err) {
        toastContext.showToast({
          title: "Video Uploaded Successfully!",
          type: ToastTypes.success,
        });
        setState({
          percentUpload: null,
        });
      } else {
        toastContext.showToast({
          title: err,
          type: ToastTypes.error,
        });
      }
    });
  };

  return (
    <div className="App">
      <div>
        <div className="topnav">
          <h1 style={{ marginBottom: "50px", textTransform: "uppercase" }}>
            One Drop Media S3 Video Upload
          </h1>
          <div className="login-container">
            <Button onClick={() => handleLogout()}>Logout</Button>
          </div>
        </div>
        <input
          type="file"
          accept="video/mp4,video/x-m4v,video/*"
          style={{ display: "none" }}
          ref={(ref) => (upload = ref)}
          onChange={(e) =>
            setState({
              imageFile: upload.files[0],
              imageName: upload.files[0].name,
            })
          }
        />
        <div className="uploadBtn">
          {state.percentUpload ? (
            <div className={"percentageUpload"}>
              <CircularProgress
                variant="determinate"
                value={state.percentUpload}
              />
            </div>
          ) : (
            <button
              onClick={(e) => {
                upload.value = null;
                upload.click();
              }}
              loading={state.uploading}
            >
              <div>
                <img src={cloud} alt={"images"} />
                <h3>Upload File</h3>
                <p>Drag and Drop file here</p>
              </div>
            </button>
          )}
        </div>
        {state.imageName ? (
          <div className="fileName">
            <span className="file">
              {" "}
              <span>{state.imageName}</span>
            </span>
            <DeleteIcon
              onClick={() =>
                setState({
                  imageFile: "",
                  imageName: "",
                })
              }
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : null}
        <button
          className={"uploadFile"}
          onClick={uploadImage}
          disabled={!state.imageName ? true : false}
          style={{ cursor: !state.imageName ? "not-allowed" : "pointer" }}
        >
          {" "}
          Upload File{" "}
        </button>
      </div>
      {!!state.response && <div>{state.response}</div>}
    </div>
  );
};
