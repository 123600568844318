import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import userPoolConfig from "../util/userPoolCofig";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { ToastContext, ToastTypes } from "../context/Toast";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Login = ({ setHasToken }) => {
  const classes = useStyles();
  const toastContext = useContext(ToastContext);

  const [state, setState] = useState({
    userName: "",
    password: "",
    isLoading: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ ...state, isLoading: true });
    const user = new CognitoUser({
      Username: state.userName,
      Pool: userPoolConfig,
    });
    const authDetails = new AuthenticationDetails({
      UserName: state.userName,
      Password: state.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem("token", "absgdtegr124aar");
        setState({ ...state, isLoading: false });
        setHasToken();
      },
      onFailure: (error) => {
        toastContext.showToast({
          title: error.message,
          type: ToastTypes.error,
        });
        setState({ ...state, isLoading: false });
      },
      newPasswordRequired: (data) => {
        localStorage.setItem("token", "absgdtegr124aar");
        setState({ ...state, isLoading: false });
        setHasToken();
      },
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={(e) => handleSubmit(e)}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => setState({ ...state, userName: e.target.value })}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => setState({ ...state, password: e.target.value })}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={state.isLoading}
          >
            {state.isLoading ? "Sign In..." : "Sign In"}
          </Button>
        </form>
      </div>
    </Container>
  );
};
